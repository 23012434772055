import React from 'react'
import { Section } from './../../../Components'

export default class PreviouslyIRL extends React.Component {
  render() {
    return (
      <Section 
        title="IRL19'da neler oldu?"
        style={{backgroundColor: "steelblue"}}
      >
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. 
          Accusamus tempora eius blanditiis quae quisquam nulla, 
          non officiis tenetur illum recusandae maxime corporis 
          officia pariatur quos vero cupiditate odio est. 
          Repellat illum perspiciatis nesciunt omnis harum sunt
          rem labore neque aliquid, at amet blanditiis? 
          Doloremque sed dolorum omnis explicabo, dolor incidunt?
        </p>

        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. 
          Accusamus tempora eius blanditiis quae quisquam nulla, 
          non officiis tenetur illum recusandae maxime corporis 
          officia pariatur quos vero cupiditate odio est. 
          Repellat illum perspiciatis nesciunt omnis harum sunt
          rem labore neque aliquid, at amet blanditiis? 
          Doloremque sed dolorum omnis explicabo, dolor incidunt?
        </p>

        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. 
          Accusamus tempora eius blanditiis quae quisquam nulla, 
          non officiis tenetur illum recusandae maxime corporis 
          officia pariatur quos vero cupiditate odio est. 
          Repellat illum perspiciatis nesciunt omnis harum sunt
          rem labore neque aliquid, at amet blanditiis? 
          Doloremque sed dolorum omnis explicabo, dolor incidunt?
        </p>
      </Section>
    )
  }
}